import CryptoJS from "crypto-js";
import AES from "crypto-js/aes";
import { assetUrl } from "./asset-url";
import constants from "./constants/constant.json";
import envLocal from "../assets/env.json";

class Env {
  private static instance: Env = null;
  private static environment: any;

  public constructor(env: any) {
    if (!Env.instance) {
      Env.environment = env;
    }
    return Env.instance;
  }

  public static getSingletonEnv() {
    return this.environment;
  }
}

export const formServiceUrl = (
  serviceName: string,
  endpoint: string,
  params?: Map<string, string>
) => {
  return getEnv().then((env) => {
    let url = `${constants.urlConstants.protocol}${env.VISOPS_SECRET_apigee_host}${constants.urlConstants.servicePrefix}${serviceName}${endpoint}`;
    // ?storeId=${storeId}
    if (params) {
      url += "?";
      let i = 0;
      params.forEach((value, key) => {
        i++;
        if (i == params.size) {
          url += key + "=" + value;
        } else {
          url += key + "=" + value + "&";
        }
      });
    }
    return url;
  });
};

export const getEnv = () => {
  const hostname = window && window.location && window.location.hostname;
  if (/^.*localhost.*/.test(hostname)) {
    return Promise.resolve(envLocal);
  }

  let env = Env.getSingletonEnv();
  if (env) {
    return Promise.resolve(env);
  }
  return fetch(assetUrl("env.json"))
    .then((res) => res.json())
    .then((env) => {
      // console.log("ENV ------> ", env);
      new Env(env);
      return env;
    });
};

export const constructESHeader = () => {
  return getEnv().then((env) => {
    return {
      Authorization: AES.decrypt(
        env.VISOPS_SECRET_pricingErrorPassword_encrypt,
        "FCC"
      ).toString(CryptoJS.enc.Utf8),
      "X-Subscription-Key": AES.decrypt(
        env.VISOPS_SECRET_pricingSubscriptionKey_encrypt,
        "FCC"
      ).toString(CryptoJS.enc.Utf8),
      "Content-Type": "application/json",
    };
  });
};
