import Parcel from "single-spa-react/parcel";
import { ParcelConfig } from "single-spa";

const storeAttributesModalConfig: ParcelConfig<{}> = () =>
  System.import("@app/visops-store-attributes-upload");

type StoreAttributesProps = {
  currentPage: number;
  isOpen: boolean;
  onClose: () => void;
};

const StoreAttributes: React.FC<StoreAttributesProps> = ({
  currentPage,
  isOpen,
  onClose,
}) => {
  return (
    <div className="store-attributes-dialog-mount">
      <Parcel
        config={storeAttributesModalConfig}
        customProps={{
          currentPage,
          isOpen,
          onClose,
        }}
      />
    </div>
  );
};

export default StoreAttributes;
