import { useEffect, useState } from "react";

export function useLocalStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = localStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue);
    }
    return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) return;
    localStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
}

export function useStorage(key, defaultValue, onLocalStorageError = null): [any, Function]{
  const localMode = window.location.protocol === "http:" || window.location.hostname === "localhost";
  const secureMode = window.location.protocol === "https:";

  const [value, setValue] = useState(defaultValue);

  useEffect(() => {
    const maxLimit = 500;
    async function fetchStorage() {
      let storedValue = null;
      if (localMode) {
        console.debug(`Loading ${key} from localStorage`);
        storedValue = localStorage.getItem(key);
        if (storedValue) {
          setValue(JSON.parse(storedValue).slice(0,maxLimit));
          return;
        }
      } else if (secureMode) {
        console.debug(`Loading ${key} from cache`);
        const cache = await caches.open(key);
        const request = new Request(`/${key}`);
        const response = await cache.match(request);
        if (response) {
          storedValue = await response.json();
          setValue(storedValue);
          return;
        }
      }
      setValue(defaultValue);
    }

    fetchStorage();
  }, [key]);

  useEffect(() => {
    if (value === undefined) return;

    async function updateStorage() {
      if (localMode) {
        console.debug(`Saving ${key} to localStorage`)
        try {
          localStorage.setItem(key, JSON.stringify(value));
        } catch (error) {
          if (
            onLocalStorageError &&
            typeof onLocalStorageError === "function"
          ) {
            const newValue = onLocalStorageError(value, error);
            localStorage.setItem(key, JSON.stringify(newValue));
          }
        }
      } else if (secureMode) {
        console.debug(`Saving ${key} to cache`)
        const cache = await caches.open(key);
        const request = new Request(`/${key}`);
        const response = new Response(JSON.stringify(value));
        await cache.put(request, response);
      }
    }

    updateStorage().then();
  }, [value, key]);

  return [value, setValue];
}

export function useSessionStorage(key, defaultValue) {
  const [value, setValue] = useState(() => {
    const storedValue = sessionStorage.getItem(key);
    if (storedValue) {
      return JSON.parse(storedValue);
    }
    return defaultValue;
  });

  useEffect(() => {
    if (value === undefined) return;
    sessionStorage.setItem(key, JSON.stringify(value));
  }, [value, key]);

  return [value, setValue];
}
