type proposedPriceStatus = {
  overAllProposedPriceStatus: {
    lastStatusUpdatedDate: string,
    exportedDate: string,
    effectiveDate: string,
    status: string
  }
}

export const stringifyNestedObjects = (rowData, headersAccessorKeyMap) => {
  return rowData.map(obj => {
    const transformedObj = {};
    for (let key in obj) {
      if (headersAccessorKeyMap.has(key) && obj.hasOwnProperty(key)) {
        const value = key.includes('Tag') ? String(obj[key]).split(",").map(tags => tags.trim()).toString().replace('[', '').replace(']', '').trim() : String(obj[key]).trim();
        key = headersAccessorKeyMap.get(key);
        if (typeof value === 'object' && value !== null) {
          transformedObj[key] = JSON.stringify(value);
        } else {
          transformedObj[key] = value;
        }
      }
    }
    return transformedObj;
  });
};
export const flattenPricePush = (data) => {
  const {
    pricePush: pricePush,
    pricePush: {
      regular: { price: regPrice = null, effectiveDate: regPriceValidFrom = null } = {},
      diesel: { price: dslPrice = null, effectiveDate: dslPriceValidFrom = null } = {},
    } = {},
    ...rest
  } = data;

  return {
    ...rest,
    pricePush,
    regPrice,
    regPriceValidFrom,
    dslPrice,
    dslPriceValidFrom,
  };
};

export const prepareProposedPriceKeys = (data) => {
  const {
    proposedPriceStatus: {
      overAllProposedPriceStatus: { status: proposedPriceOverallStatus = null, effectiveDate: effectiveDate = null} = {},
    } = {},
    ...rest
  } = data;

 // const proposedPriceOverallAge: string = convertToAge(effectiveDate);
  return ({
    ...rest,
    proposedPriceOverallStatus,
  //  proposedPriceOverallAge,
  });

  
}
function convertToAge(exportedDate: string): string {

  if(exportedDate === null || exportedDate === "") {
    // console.log("visops-list-view date",exportedDate );
    return "";
  }
  const givenDate = new Date(exportedDate); // Convert string to Date object
  const currentDate = new Date(); // Get the current date

  // Calculate the difference in time (in milliseconds)
  const differenceInTime = currentDate.getTime() - givenDate.getTime();

  // Convert milliseconds to days (1 day = 24 * 60 * 60 * 1000 milliseconds)
  const differenceInDays = Math.floor(differenceInTime / (1000 * 3600 * 24));

  return String(differenceInDays); 
}

