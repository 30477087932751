// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-tooltip {
  width: auto;
  height: auto;
  border: 1px solid cornflowerblue;
  overflow: hidden;
}

.custom-tooltip p {
  margin: 5px;
  white-space: nowrap;
}

.custom-tooltip p:first-of-type {
  font-weight: bold;
}

.manageSiteClose {
  cursor: pointer;
}

.custom-toolbar{
  display: flex; 
  gap: 0.5rem; 
  justify-content: space-between; 
  flex-grow: inherit;
  background-color: hsl(120, 40%, 96%);
}`, "",{"version":3,"sources":["webpack://./src/app.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,YAAY;EACZ,gCAAgC;EAChC,gBAAgB;AAClB;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,iBAAiB;AACnB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,aAAa;EACb,WAAW;EACX,8BAA8B;EAC9B,kBAAkB;EAClB,oCAAoC;AACtC","sourcesContent":[".custom-tooltip {\n  width: auto;\n  height: auto;\n  border: 1px solid cornflowerblue;\n  overflow: hidden;\n}\n\n.custom-tooltip p {\n  margin: 5px;\n  white-space: nowrap;\n}\n\n.custom-tooltip p:first-of-type {\n  font-weight: bold;\n}\n\n.manageSiteClose {\n  cursor: pointer;\n}\n\n.custom-toolbar{\n  display: flex; \n  gap: 0.5rem; \n  justify-content: space-between; \n  flex-grow: inherit;\n  background-color: hsl(120, 40%, 96%);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
