import { useEffect } from "react";
import WebFont from "webfontloader";
import ListViewDrawer from "./components/listViewDrawer/ListViewDrawer";
import { CustomProps } from "./utils/dataDefinitions/dataTypes";
type Props = {
  customProps: CustomProps;
};

export default function Root({customProps}: Props) {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ["Roboto", "Arial"],
      },
    });
  }, []);
  console.log("customProps >>>", customProps);
  return <ListViewDrawer {...customProps}/>
}
